import Tealium from '@4tn/webx-analytics';
import { ROUTES, TEALIUM_PAGE_CATEGORY } from '../../client/constants';

export const setupTealium = (tealiumEnv: string) => {
  Tealium.setup({
    account: 'talpa',
    profile: 'video-kijk-web',
    environment: tealiumEnv,
    defaultLayer: {
      app_brandname: 'kijk.nl',
      app_broadcastername: 'talpa tv',
      app_companyname: 'talpa tv',
      app_cookiepreference: 'optimal',
      app_datalayer_version: 1.2,
      app_formatcountry: 'nl',
      app_name: 'kijk.nl website',
      app_platform: 'web',
      app_version: 2,
      content_language: 'nl'
    }
  });
};

export const setDataLayer = (dataLayer: { [key: string]: string | boolean | number | null }): void => {
  Tealium.setDataLayer(dataLayer);
};

export const trackView = (dataLayer: { [key: string]: string | boolean | number | null } = {}): void => {
  Tealium.view(dataLayer);
};

export const trackClick = ({
  label,
  name,
  category,
  value,
  defaultValues = {}
}: {
  label: string | null;
  name: string;
  category: string;
  value?: string | number | null;
  defaultValues?: { [key: string]: string | boolean | number | null };
}): void => {
  Tealium.link({
    event_value: value ?? null,
    ...defaultValues,
    event_category: category,
    event_name: name,
    event_label: label
  });
};

const getPageCategory = (page?: IPage): string => {
  const { page: path, query } = page || {};
  if (path === ROUTES.HOME) return TEALIUM_PAGE_CATEGORY.HOME;
  if (query?.videoId) return TEALIUM_PAGE_CATEGORY.DETAIL;
  return TEALIUM_PAGE_CATEGORY.INDEX;
};

export const setPageCategory = (page?: IPage) => {
  const category = getPageCategory(page);
  setDataLayer({ page_category: category });
};
