import React, { ReactElement } from 'react';
import App from 'next/app';
import { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import dynamic from 'next/dynamic';
import Main from 'components/Main';
import MetaHeader from 'components/Meta';
import VideoJWPlayer, { setConvivaKey } from 'components/PlayerContainer/VideoJWPlayer';
import featureTooling from 'components/FeatureTooling';
import acceptAllConsent from 'lib/acceptAllConsent';
import { match } from 'lib/routes';
import { getCurrentRouteMeta } from 'lib/helpers/getCurrentRouteMeta';
import { getViewMode } from 'lib/helpers/helpers';
import withApollo from 'lib/withApollo/withApollo';
import PlayerOptionsQuery from 'queries/playerOptions/playerOptionsQuery.query';
import NavigationsQuery from 'queries/navigations/navigations.query';
import { detectAdBlock } from 'lib/adBlockDetection';
import { trackView, setDataLayer, setupTealium, setPageCategory } from 'lib/tealium';
import probeDrmSupport from 'lib/probeDrmSupport';
import { setCustomAttributes } from 'lib/newrelic';
import getClientIP from 'lib/getClientIP';
import loadHotjar from 'lib/helpers/loadHotjar';
import { setVideoApiUrl } from 'lib/addDrm';
import { debouncedHandleScroll } from 'lib/helpers/trackUserScroll';
import { AVAILABLE_REGIONS, FEATURE_SLUG, IS_CLIENT, PROMO_QUERY_CLIENT } from '../src/client/constants';

class KijkApp extends App<IKijkAppProps & { session: Session }> {
  apolloClient: ApolloClient<object>;

  persistentData = {
    host: '',
    viewMode: '',
    seriesJson: '',
    dataFileUrl: '',
    countryCode: '',
    videoApiUrl: '',
    convivaKey: ''
  };

  public static async getInitialProps({
    Component,
    ctx,
    router
  }: {
    Component: any;
    ctx: any;
    router: any;
  }): Promise<any> {
    const countryCode = ctx.req?.headers['cloudfront-viewer-country'] || AVAILABLE_REGIONS.NETHERLANDS;
    const seriesJson =
      countryCode === AVAILABLE_REGIONS.NETHERLANDS
        ? (process.env.SERIES_JSON as string)
        : (process.env.SERIES_JSON_GLOBAL as string);

    const pageProps = Component.getInitialProps
      ? (await Component.getInitialProps({ ...ctx, seriesJson, countryCode })) || {}
      : {};
    const { apolloClient } = ctx as { apolloClient: ApolloClient<object> };
    const { page, query } = match(ctx.req, ctx.asPath);
    (pageProps as any).page = { ...page, query };
    if (pageProps.statusCode === 301) {
      if (IS_CLIENT) {
        router.push(pageProps.redirectUrl);
      } else {
        ctx.res.writeHead(301, { Location: pageProps.redirectUrl });
        ctx.res.end();
      }
    } else if (pageProps.statusCode && !IS_CLIENT) {
      ctx.res.statusCode = pageProps.statusCode;

      if (pageProps.statusCode === 500) {
        ctx.res.setHeader('Cache-Control', 'max-age=0'); // Prevent caching of errors
      }
    }

    pageProps.countryCode = countryCode;
    pageProps.host = process.env.DOMAIN;
    pageProps.viewMode = getViewMode(ctx);
    pageProps.dataFileUrl = process.env.DATA_FILE_URL;
    pageProps.seriesJson = seriesJson;
    pageProps.tealiumEnv = process.env.TEALIUM_ENV;
    pageProps.videoApiUrl = process.env.VIDEO_API_ENDPOINT;
    pageProps.convivaKey = process.env.CONVIVA_KEY;
    let playerOptions = {};
    try {
      const [{ data }] = await Promise.all([
        apolloClient.query<{ playerOptions: { config: any; debug: boolean } }>({
          query: PlayerOptionsQuery
        }),
        apolloClient.query({
          query: NavigationsQuery,
          variables: { client: PROMO_QUERY_CLIENT.DESKTOP }
        })
      ]);
      const {
        playerOptions: { config, debug }
      } = data;

      playerOptions = { config, debug };
    } catch {}

    return { pageProps, playerOptions };
  }

  constructor(props: any) {
    super(props);
    const { playerOptions, pageProps } = this.props;

    const { host, viewMode, seriesJson, dataFileUrl, countryCode, videoApiUrl, convivaKey } = pageProps;
    this.persistentData = {
      host,
      viewMode,
      seriesJson,
      countryCode,
      dataFileUrl,
      videoApiUrl,
      convivaKey
    };
    setConvivaKey(convivaKey);
    setVideoApiUrl(videoApiUrl);
    VideoJWPlayer.setPlayerOptions(playerOptions);
  }

  componentDidMount(): void {
    if (IS_CLIENT) {
      const { dataFileUrl, tealiumEnv, viewMode, page, countryCode } = this.props.pageProps;

      featureTooling.init(dataFileUrl, viewMode, countryCode).then(() => {
        const variables = featureTooling.getFeatureVariables<{
          startTime: number;
          endTime: number;
          scriptUrl: string;
        }>(FEATURE_SLUG.HOTJAR);
        loadHotjar(variables);
      });

      getClientIP().then(ipData => {
        if (ipData) {
          setCustomAttributes({
            clientIP: ipData.ip
          });
        }
      });

      setupTealium(tealiumEnv);

      setPageCategory(page);
      window.addEventListener('scroll', debouncedHandleScroll);
      window.TN_GDPR_BYPASS = window.location.href.includes('gdprBypass=true');
      if (window.TN_GDPR_BYPASS && window.OneTrustEnabled) {
        acceptAllConsent();
      }

      detectAdBlock().then(detected => {
        setDataLayer({ adblock: detected });
        const { videoId } = page?.query || {};
        trackView({ media_id: videoId || null });
      });
      probeDrmSupport().then(({ drmSupported }) => {
        setCustomAttributes({
          drmSupported,
          userAgent: navigator.userAgent
        });
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', debouncedHandleScroll);
  }

  componentDidUpdate(prevProps: IKijkAppProps): void {
    const { page } = this.props.pageProps;
    const { videoId, formatSlug } = page?.query || {};
    const { page: prevPage } = prevProps.pageProps;

    if (
      prevPage?.name !== page?.name ||
      videoId !== prevPage?.query.videoId ||
      formatSlug !== prevPage?.query.formatSlug
    ) {
      setPageCategory(page);
      setDataLayer({ scroll_percentage: null });
      trackView({ media_id: videoId || null });
      featureTooling.reset();
    }
  }

  render(): ReactElement {
    const { Component, apolloClient, session } = this.props as IKijkAppProps & { session: Session };

    const pageProps = { ...this.props.pageProps, ...this.persistentData };
    const CurrentRouteName = pageProps.page?.name || '';
    const CurrentRouteMeta: IRouteMeta[] | null = getCurrentRouteMeta(CurrentRouteName);

    const IsHeaderTransparent = !!CurrentRouteMeta && CurrentRouteMeta.some(meta => meta.isHeaderTransparent);

    if (this.props.router && this.props.router.asPath === '/unsupported-browser') {
      const UnsupportedBrowser = dynamic(() => import('./unsupported-browser'));
      return (
        <>
          <MetaHeader viewMode={pageProps.viewMode} host={pageProps.host} currentRouteName={CurrentRouteName} />
          <UnsupportedBrowser />
        </>
      );
    }
    return (
      <SessionProvider session={session} refetchOnWindowFocus={false}>
        <ApolloProvider client={apolloClient}>
          <Main
            isHeaderTransparent={IsHeaderTransparent}
            show404={pageProps.statusCode === 404}
            show404Message={pageProps.message}
            client={apolloClient}
            viewMode={pageProps.viewMode}
            currentRouteName={CurrentRouteName}
            host={pageProps.host}
          >
            <Component {...pageProps} client={apolloClient} />
          </Main>
        </ApolloProvider>
      </SessionProvider>
    );
  }
}

export default withApollo(KijkApp);
