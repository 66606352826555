import axios from 'axios';
import Tealium from '@4tn/webx-analytics';
import isSearchBot from 'lib/isSearchBot';
import { IS_CLIENT } from '../../constants';
import User from './User';

const RETRY_LIMIT = 20;

class FeatureTooling {
  isLoaded = false;
  viewMode = '';
  debug = IS_CLIENT && document.cookie.includes('__FEATURE_DEBUG__');
  dataFileUrl: string;
  features: IFeature[] = [];
  activeFeatures: IFeature[] = [];
  activeExperiments: {
    featureSlug: string;
    slug: string;
    variant: IVariant | null;
  }[] = [];
  featuresCache: { [id: string]: boolean } = {};
  user?: User;

  constructor() {
    if (IS_CLIENT && !isSearchBot()) {
      this.user = new User(this.log);
    }
  }

  init = async (dataFileUrl: string, viewMode: string, countryCode?: string): Promise<void> => {
    this.dataFileUrl = dataFileUrl;
    this.viewMode = viewMode;
    try {
      const { data } = await axios.get<FeatureTooling['features']>(this.dataFileUrl);
      this.features = data;
      if (this.user) {
        this.user.setAttributes({ device: viewMode, currentPath: document.location.pathname, countryCode });
      }

      this.updateActiveFeatures();
    } catch (error) {
      this.log(`Failed to load data file: ${(error as any).message}`);
    }
    this.isLoaded = true;
  };

  private updateActiveFeatures = () => {
    this.activeFeatures = this.features.filter(({ slug, enabled, audience }) => {
      if (enabled && audience) {
        // audience are disabled server-side
        if (!this.user) return false;
        const inAudience = this.user.isInAudience(audience);
        this.log(`visitor is ${inAudience ? '' : 'not '}in audience of feature ${slug}`);
        return inAudience;
      }
      return enabled;
    });

    if (this.user) {
      this.activeExperiments = this.activeFeatures.reduce((experiments, feature: IFeature) => {
        if (feature.experiment && feature.experiment.enabled) {
          const experiment = {
            featureSlug: feature.slug,
            slug: `${feature.slug}-test`,
            variant: (this.user as User).getVariant(feature)
          };
          return [...experiments, experiment];
        }
        return experiments;
      }, [] as FeatureTooling['activeExperiments']);

      Tealium.setDataLayer({
        experiment_features: this.activeFeatures.map(({ slug }) => slug).join('|'),
        experiment_tests: Object.values(this.activeExperiments)
          .map(({ slug }) => slug)
          .join('|'),
        experiment_tests_variants: Object.values(this.activeExperiments)
          .map(({ variant }) => variant?.key || '')
          .join('|')
      });
    }
  };

  private log = (msg: string): void => {
    if (!this.debug) return;
    // eslint-disable-next-line no-console
    console.log(`%c [FeatureTooling] ${msg}`, 'font-weight:bold');
  };

  waitUntilReady = (): Promise<void> =>
    new Promise(resolve => {
      if (this.isLoaded) {
        resolve();
        return;
      }

      let retry = 0;
      const interval = setInterval(() => {
        if (this.isLoaded || retry >= RETRY_LIMIT) {
          clearInterval(interval);
          resolve();
          return;
        }
        retry += 1;
      }, 100);
    });

  isFeatureEnabled = (featureSlug: string): boolean => {
    if (!this.isLoaded || !this.features.length) {
      return false;
    }
    if (this.featuresCache[featureSlug] === undefined) {
      const enabled = this.activeFeatures.some(activeFeature => activeFeature.slug === featureSlug);

      if (enabled) {
        const experiment = this.activeExperiments.find(
          activeExperiment => activeExperiment.featureSlug === featureSlug
        );
        if (experiment?.variant) {
          this.featuresCache[featureSlug] = experiment.variant.featureEnabled;
          this.log(
            `user is using variant '${experiment.variant.key}' for ${featureSlug} | feature is ${
              this.featuresCache[featureSlug] ? '' : 'not '
            }enabled`
          );
          return this.featuresCache[featureSlug];
        }
      }
      this.featuresCache[featureSlug] = enabled;
      const feature = this.features.find(({ slug }) => slug === featureSlug);

      this.log(
        feature
          ? `feature ${featureSlug} is ${enabled ? '' : 'not '}enabled`
          : `feature ${featureSlug} not found in data file`
      );
    }
    return this.featuresCache[featureSlug];
  };

  getFeatureVariant = (featureSlug: string): string | null => {
    if (!this.isLoaded || !this.features.length) {
      return null;
    }
    this.isFeatureEnabled(featureSlug);

    const experiment = this.activeExperiments.find(activeExperiment => activeExperiment.featureSlug === featureSlug);
    return experiment?.variant?.key || null;
  };

  getFeatureVariable = <T>(featureSlug: string, variableKey: string, defaultValue: any = null): T | null => {
    const variables = this.getFeatureVariables<{ [key: string]: unknown }>(featureSlug);

    if (variables) {
      return typeof variables[variableKey] !== 'undefined' ? variables[variableKey] : defaultValue;
    }
    return defaultValue;
  };

  getFeatureVariables = <T>(featureSlug: string): T | null => {
    if (!this.isLoaded || !this.features.length || !this.isFeatureEnabled(featureSlug)) {
      return null;
    }

    const experiment = this.activeExperiments.find(activeExperiment => activeExperiment.featureSlug === featureSlug);
    if (experiment?.variant && !experiment.variant.useDefaultVariables) {
      return experiment.variant.variables;
    }

    const { variables } = this.features.find(({ slug }) => slug === featureSlug) || {};
    return variables;
  };

  reset = (): void => {
    this.featuresCache = {};
    if (this.user) {
      this.user.setAttributes({ currentPath: document.location.pathname });
    }
    this.updateActiveFeatures();
  };
}

const featureTooling = new FeatureTooling();
if (IS_CLIENT) {
  (window as any).__featureTooling = featureTooling;
}
export default featureTooling;
